@import "styles/variables";

.root {
    :global {
        text-align: center;
        color: $font-color;

        .card-img {
            max-width: 80%;
            max-height: 50px;
            @include trans;
            @include object-fit(contain);

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .marquee-wrapper > * {
            margin-right: 0;
            white-space: nowrap;

            .marquee-image {
                object-fit: contain;
              }
          }

        .hawk-3-talent-solutions,
        .driving-force {
            .card-img {
                max-height: 40px;
            }
        }

        @media (max-width: $breakpoint-sm) {
            h3 {
                font-size: 1.2rem;
            }
        }
    }
}